import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import '@fontsource/inter/400.css'
import '@fontsource/inter/700.css'

import GilroyBoldWoff2 from 'src/fonts/Gilroy-Bold.woff2'
import GilroyExtraBoldWoff2 from 'src/fonts/Gilroy-ExtraBold.woff2'

const gilroyExtraBoldFontFamily = `"Gilroy-ExtraBold", Helvetica`
const gilroyBoldFontFamily = `"Gilroy-Bold", Helvetica`

const baseSpacing = 8

const palette = {
  background: {
    default: '#F6F6FA',
  },
  text: {
    primary: '#142E46',
  },
  primary: {
    main: '#BA36BC',
  },
  secondary: {
    main: '#574AF0',
  },
}

const theme = createTheme({
  spacing: baseSpacing,
  typography: {
    fontFamily: 'Inter, Arial',
    h1: {
      fontFamily: gilroyExtraBoldFontFamily,
      fontSize: '2.5rem',
    },
    h2: {
      fontFamily: gilroyBoldFontFamily,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: gilroyBoldFontFamily,

      fontSize: '1.2rem',
    },
    subtitle1: {
      fontSize: '1.3rem',
    },
    tag: {
      fontFamily: gilroyBoldFontFamily,
      color: palette.secondary.main,
      fontSize: '1rem',
    },

    legal: {
      fontSize: '0.8rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Gilroy-Bold',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 300,
          src: `local('Gilroy Bold'), local('Gilroy-Bold'),
            url(${GilroyBoldWoff2}) format('woff2')`,
        },
        fallbacks: [
          {
            '@font-face': {
              fontFamily: 'Gilroy-ExtraBold',
              fontStyle: 'normal',
              fontDisplay: 'swap',
              fontWeight: 300,
              src: `local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
            url(${GilroyExtraBoldWoff2}) format('woff2')`,
            },
          },
        ],
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // no capitalize
          padding: `${baseSpacing * 1.2}px ${baseSpacing * 2.5}px`,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 3,
      },
    },
  },

  palette,
})

export default responsiveFontSizes(theme)
