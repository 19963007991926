import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import { Paper, Typography } from '@mui/material'
import supportedBrowsers from '../supportedBrowsers'

const Root = styled(Paper)({
  position: 'fixed',
  width: '100%',
  zIndex: 99999,
  bottom: 0,
  flexGrow: 1,
})

const UnsupportedBrowserCheckRoot = styled('div')(({ theme }) => ({
  color: 'white',
  backgroundColor: '#EA4C3C',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  textAlign: 'center',
}))

const UnsupportedBrowserCheck = () => {
  const [isUnsupported, setIsUnsupported] = useState(false)

  // for serverside rendering we have to do this in useEffect
  useEffect(() => {
    setIsUnsupported(!supportedBrowsers.test(navigator.userAgent))
  }, [])

  if (isUnsupported) {
    return (
      <UnsupportedBrowserCheckRoot>
        <Typography>
          Uw webbrowser is verouderd. Update uw browser voor meer veiligheid,
          snelheid en om deze site optimaal te kunen gebruiken.
        </Typography>
      </UnsupportedBrowserCheckRoot>
    )
  }
  return null
}

const Notices = () => (
  <Root>
    <UnsupportedBrowserCheck />
  </Root>
)

export default Notices
