import React from 'react'
import { Helmet } from 'react-helmet'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import theme from 'src/theme'

import { defaultTitle, defaultDescription } from 'src/config'

import Notices from 'src/Notices'

const TopLayout = ({ children }) => (
  <>
    <Helmet>
      <html lang="nl" />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="business.business" />
      <meta property="og:url" content="/" />
      <title>{defaultTitle}</title>
      <meta property="description" content={defaultDescription} />
    </Helmet>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
      <Notices />
    </ThemeProvider>
  </>
)

export default TopLayout
