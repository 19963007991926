/* eslint-disable prefer-destructuring */
// get environment variables
// process.env is a special object so we can't use destructuring
const GATSBY_BASE_URL = process.env.GATSBY_BASE_URL
const GATSBY_USE_HTTP = process.env.GATSBY_USE_HTTP
const GATSBY_DONT_USE_WWW = process.env.GATSBY_DONT_USE_WWW
const GATSBY_API_URL = process.env.GATSBY_API_URL
const GATSBY_DEFAULT_SUBDOMAIN = process.env.GATSBY_DEFAULT_SUBDOMAIN

const https = !GATSBY_USE_HTTP
const www = !GATSBY_DONT_USE_WWW
const defaultSubdomain = GATSBY_DEFAULT_SUBDOMAIN || 'www'

const baseUrl = GATSBY_BASE_URL || 'appeal.services'

const makeUrl = (path, subdomain = www ? defaultSubdomain : '') =>
  `http${https ? 's' : ''}://${subdomain}${
    subdomain ? '.' : ''
  }${baseUrl}${path}`

export const homeUrl = `https://www.${baseUrl}`
export const defaultTitle = 'Appeal Services'
export const defaultDescription =
  'Appeal provides software-as-a-service to advance your group or class action from campaign to distribution'
export const contactEmail = 'info@appeal.services'

export const apiUrl = GATSBY_API_URL || makeUrl('', 'lj2')
